import { useEffect } from "react";
import "./css/error.css";

const Error404 = ({ errorimage }) => {
  useEffect(() => {
    document.title = "Error | PM Speciality Coatings";
    return () => {
      document.title = "PM Speciality Coatings";
    };
  }, []);
  return (
    <section class="error">
      <div class="home__container container">
        <div class="home__data">
          <span class="home__subtitle">Error 404</span>
          <h1 class="home__title">Hey Buddy</h1>
          <p class="home__description">
            Oops! It seems like the coating you're looking for is undergoing a
            transformation.
          </p>
          <a href="/" class="home__button">
            Go Home
          </a>
        </div>
        <div class="home__img">
          <img src={errorimage} alt="" />
          <div class="home__shadow"></div>
        </div>
      </div>
      <footer class="home__footer">
        <span>
          <a href="tel:+919422701244" target="_blank" rel="noreferrer">
            +91 9422701244
          </a>
        </span>
        <span>|</span>
        <span>
          <a
            href="mailto:pmscoaters@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            pmscoaters@gmail.com
          </a>
        </span>
      </footer>
    </section>
  );
};

export default Error404;
