import { useEffect } from "react";
import ContactShort from "./ContactShort";

/* eslint-disable jsx-a11y/anchor-is-valid */
const Products = ({ productsData, gallery, productbanner }) => {
  useEffect(() => {
    document.title = "Products | PM Speciality Coatings";
    return () => {
      document.title = "PM Speciality Coatings";
    };
  }, []);
  return (
    <section className="products">
      <div
        className="bradcam_area"
        style={{ backgroundImage: `url(${productbanner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bradcam_text text-center">
                <h3>Products</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery_area">
        <div className="container">
          <div className="row grid">
            {productsData.map((prod, index) => (
              <div
                className={`col-xl-${prod.xl} col-lg-6 grid-item cat${prod.cat} col-md-6`}
              >
                <div className="single-gallery mb-20">
                  <div className="portfolio-img">
                    <img src={gallery[index]} alt={prod.title} />
                  </div>
                  <div className="gallery_hover">
                    <a className="hover_inner" target="_blank">
                      <h3>{prod.title}</h3>
                      <span>{prod.body}</span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ContactShort />
    </section>
  );
};

export default Products;
