const ContactShort = () => {
  return (
    <div className="project_area">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="project_info text-center">
              <h3>Contact Us For Your Coating Requirements!</h3>
              <p>
                We are working in association with many renowned industries in
                automotive,
                <br />
                aerospace, electronics, electrical and various esteemed
                projects.
              </p>
              <a
                href="/contact"
                className="boxed-btn3-white"
                style={{ textDecoration: "none" }}
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactShort;
