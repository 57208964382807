import "./css/Inventory.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import "reactjs-popup/dist/index.css";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { ref, set, getDatabase, update, remove } from "firebase/database";
import { app } from "./firebase";
import { useEffect, useState } from "react";

const db = getDatabase(app);

const Batch = ({ invbanner, items, batches, unfinishedgoods }) => {
  const history = useHistory();
  const [unfStatus, setUnfStatus] = useState(false);
  const [userData, setUserData] = useState([]);
  const [maindate, setMainDate] = useState("");
  const batchSlice = batches.slice(batches.length - 5);
  const date = new Date();
  const keyID = date.getTime() + Math.floor(Math.random() * 1000);

  useEffect(() => {
    const userDataString = sessionStorage.getItem(
      "firebase:authUser:AIzaSyARtZtGcTVyARm-iZrX1YNvhiBSMuGCib4:[DEFAULT]"
    );
    if (userDataString) {
      const userDataJSON = JSON.parse(userDataString);
      setUserData(userDataJSON.email);
    }
  }, []);

  const filteredquan = [].concat(
    ...items.map((ite) => [{ quan: ite.quantity, id: ite.id }])
  );
  const filteredquan1 = [].concat(
    ...unfinishedgoods.map((ite) => [{ quan: ite.quantity, id: ite.id }])
  );
  const filteredItems = batchSlice.map((bat) => bat.items);
  const filteredItems1 = batchSlice.map((bat) => bat.unfinished);

  const BatchSchema = Yup.object().shape({
    name: Yup.string()
      .required("This is a required Field")
      .min(2, "The name Should be at least 2 characters long"),
    company_name: Yup.string()
      .required("Company Name field is Required.")
      .min(2, "The Company Name should be at least 2 Characters long"),
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        "http://api.timezonedb.com/v2.1/get-time-zone?key=J2S82802X7A0&format=json&by=position&lat=20.0081408&lng=73.7574912"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      const formatted = jsonData.formatted;
      const dateFormat = formatted.split(" ")[0];
      setMainDate(dateFormat);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    document.title = "Batch | PM Speciality Coatings";
    return () => {
      document.title = "PM Speciality Coatings";
    };
  }, []);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    fetchData();
    const result = [];
    const itemsarr1 = [].concat(
      ...values.unfinishedgoods.map((ite) => [
        { quan: ite.quantity, id: ite.id },
      ])
    );
    const itemsarr = [].concat(
      ...values.items.map((ite) => [{ quan: ite.quantity, id: ite.id }])
    );

    const updatedQuantities = filteredquan1
      .map((filteredItem) => {
        const matchingItem = itemsarr1.find(
          (item) => item.id === filteredItem.id
        );
        if (matchingItem) {
          const changedQuantity = filteredItem.quan - matchingItem.quan;
          return changedQuantity !== 0
            ? { id: filteredItem.id, quan: changedQuantity }
            : null;
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);
    const mapA = new Map();
    filteredquan.forEach((item) => mapA.set(item.id, item.quan));
    itemsarr.forEach((item) => {
      if (mapA.has(item.id)) {
        mapA.set(item.id, mapA.get(item.id) - item.quan);
      } else {
        mapA.set(item.id, item.quan);
      }
    });

    updatedQuantities.map((ite) =>
      update(ref(db, `unfinished/${ite.id}`), {
        quantity: ite.quan,
      })
    );

    set(ref(db, `batches/${keyID}`), {
      id: keyID,
      name: values.name,
      company: values.company_name,
      person: values.person,
      unfinished: values.unfinishedgoods,
      items: values.items,
      date: "2024-05-09",
    });

    mapA.forEach((quan, id) => {
      result.push({ id, quan });
    });
    result.map((dat) =>
      update(ref(db, `inventory/${dat.id}`), {
        quantity: dat.quan,
      })
    );

    setSubmitting(false);
    setUnfStatus(false);
    resetForm();
  };

  const handleDelete = (id) => {
    remove(ref(db, `batches/${id}`));
  };
  const handleHistory = () => {
    history.push("/history");
    sessionStorage.setItem("historystatus", true);
  };

  return (
    <section className="batch">
      <div
        className="bradcam_area"
        style={{ backgroundImage: `url(${invbanner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bradcam_text text-center">
                <h3>Batches</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="batch-container1">
        <h2 className="section-title1">Batch</h2>
        <div className="button-container">
          <Link to="/inv" className="link-button">
            Inventory
          </Link>
          <Link to="/unfinished-goods" className="link-button">
            Unfinished Goods
          </Link>
        </div>
        <h2 className="section-title1">Batch List</h2>
        <div style={{ marginLeft: "40px" }}>
          <button onClick={handleHistory}>History</button>
        </div>
        <table className="inventory-table1">
          <thead>
            <tr>
              <th>Name</th>
              <th>Company Name</th>
              <th>Person</th>
              <th>Date</th>
              <th>Unfinished Goods</th>
              <th>Items</th>
              <th></th>
            </tr>
          </thead>
          {batchSlice.map((bat, index) => (
            <tbody>
              <tr>
                <td>{bat.name}</td>
                <td>{bat.company}</td>
                <td>{bat.person}</td>
                <td>{bat.date}</td>
                {filteredItems1[index].map((fil) => (
                  <td>
                    <strong>{fil.name}: </strong>
                    {fil.quantity}
                  </td>
                ))}
                {filteredItems[index].map((fil) => (
                  <div className="item-popup">
                    <td>
                      <strong>{fil.name}: </strong>
                      {fil.quantity}
                    </td>
                  </div>
                ))}
                <td>
                  {userData === "admin@gmail.com" && (
                    <button onClick={() => handleDelete(bat.id)}>Delete</button>
                  )}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <div className="add-to-batch1 add-item-container1">
          <h3 className="section-title1">Create New Batch</h3>

          <div className="batch-actions1 contact-form">
            <Formik
              initialValues={{
                name: "",
                company_name: "",
                person: sessionStorage.getItem("user"),
                items: [],
                selectedItem: "",
                unfinishedgoods: [],
                selectedUnfinishedGood: "",
              }}
              validationSchema={BatchSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched, isSubmitting }) => (
                <Form>
                  {errors.name && touched.name ? (
                    <sub style={{ color: "red" }}>{errors.name}</sub>
                  ) : null}
                  <label htmlFor="name">Batch Name:</label>
                  <Field
                    type="text"
                    placeholder="Name"
                    name="name"
                    autoComplete="off"
                  />
                  {errors.company_name && touched.company_name ? (
                    <sub style={{ color: "red" }}>{errors.company_name}</sub>
                  ) : null}
                  <label htmlFor="company_name">Company Name:</label>
                  <Field
                    type="text"
                    placeholder="Company Name"
                    name="company_name"
                    autoComplete="off"
                  />
                  {errors.person && touched.person ? (
                    <sub style={{ color: "red" }}>{errors.person}</sub>
                  ) : null}
                  <label htmlFor="person">User: </label>
                  <Field
                    name="person"
                    className="drop"
                    type="text"
                    disabled
                    value={sessionStorage.getItem("user")}
                  ></Field>
                  <div>
                    <label htmlFor="selectedItem">Select Item:</label>
                    <br />
                    <Field as="select" className="drop" name="selectedItem">
                      <option value="">Select an item</option>
                      {items.map((item) => (
                        <option key={item.id} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                    <button
                      type="button"
                      className="batch-btn"
                      onClick={() => {
                        if (values.selectedItem) {
                          const newItem = JSON.parse(values.selectedItem);
                          setFieldValue("items", [...values.items, newItem]);
                          setFieldValue("selectedItem", "");
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                  <div>
                    {values.items.map((item, index) => (
                      <div key={index}>
                        <label>
                          {item.name}:<br />
                          Quantity:
                          <input
                            type="number"
                            value={item.quantity}
                            max={item.quantity}
                            onChange={(e) => {
                              const updatedItems = [...values.items];
                              updatedItems[index].quantity = parseInt(
                                e.target.value
                              );
                              setFieldValue("items", updatedItems);
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              const updatedItems = [...values.items];
                              updatedItems.splice(index, 1);
                              setFieldValue("items", updatedItems);
                            }}
                          >
                            Remove
                          </button>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div>
                    <label htmlFor="selectedUnfinishedGood">
                      Select Unfinished Good:
                    </label>
                    <br />
                    {!unfStatus && (
                      <Field
                        as="select"
                        className="drop"
                        name="selectedUnfinishedGood"
                      >
                        <option value="">Select an unfinished good</option>
                        {unfinishedgoods.map((unfinishedGood) => (
                          <option
                            key={unfinishedGood.id}
                            value={JSON.stringify(unfinishedGood)}
                          >
                            {unfinishedGood.name}
                          </option>
                        ))}
                      </Field>
                    )}
                    {!unfStatus && (
                      <button
                        type="button"
                        className="batch-btn"
                        onClick={() => {
                          if (values.selectedUnfinishedGood) {
                            const newUnfinishedGood = JSON.parse(
                              values.selectedUnfinishedGood
                            );
                            setFieldValue("unfinishedgoods", [
                              ...values.unfinishedgoods,
                              newUnfinishedGood,
                            ]);
                            setFieldValue("selectedUnfinishedGood", "");
                          }
                          setUnfStatus(true);
                        }}
                      >
                        Add
                      </button>
                    )}
                  </div>
                  <div>
                    {values.unfinishedgoods.map((unfinishedGood, index) => (
                      <div key={index}>
                        <label>
                          {unfinishedGood.name}:<br />
                          Quantity:
                          <input
                            type="number"
                            value={unfinishedGood.quantity}
                            max={unfinishedGood.quantity}
                            onChange={(e) => {
                              const updatedUnfinishedGoods = [
                                ...values.unfinishedgoods,
                              ];
                              updatedUnfinishedGoods[index].quantity = parseInt(
                                e.target.value
                              );
                              setFieldValue(
                                "unfinishedgoods",
                                updatedUnfinishedGoods
                              );
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              const updatedUnfinishedGoods = [
                                ...values.unfinishedgoods,
                              ];
                              updatedUnfinishedGoods.splice(index, 1);
                              setFieldValue(
                                "unfinishedgoods",
                                updatedUnfinishedGoods
                              );
                              setUnfStatus(false);
                            }}
                          >
                            Remove
                          </button>
                        </label>
                      </div>
                    ))}
                  </div>
                  <button
                    type="submit"
                    className="boxed-btn contact-form-btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Batch;
