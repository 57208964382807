import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "./css/Inventory.css";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Login = ({ loginbanner, handleSignIn, msg, loginstatus }) => {
  const history = useHistory();
  const SignInSchema = Yup.object().shape({
    username: Yup.string().required("Username Cannot be Empty"),
    password: Yup.string().required("Password Cannot be Empty"),
  });
  console.log(loginstatus);
  if (loginstatus) {
    history.push("/inv");
  }

  useEffect(() => {
    document.title = "Login | PM Speciality Coatings";
    return () => {
      document.title = "PM Speciality Coatings";
    };
  }, []);

  return (
    <section className="login">
      <div
        className="bradcam_area"
        style={{ backgroundImage: `url(${loginbanner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bradcam_text text-center">
                <h3>Login</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sign">
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={SignInSchema}
          onSubmit={handleSignIn}
        >
          {({ errors, touched }) => (
            <Form>
              <label>
                Username:{" "}
                {errors.username && touched.username ? (
                  <sub style={{ color: "red" }}>{errors.username}</sub>
                ) : null}
              </label>
              <Field type="text" name="username" autoComplete="off" />
              <label>
                Password:{" "}
                {errors.password && touched.password ? (
                  <sub style={{ color: "red" }}>{errors.password}</sub>
                ) : null}
              </label>
              <Field type="password" name="password" autoComplete="off" />
              <p>{msg}</p>

              <button type="submit">Sign In</button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default Login;
