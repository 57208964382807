import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom";
import Navbar from "./Navbar";
import Home from "./Home";
import Footer from "./Footer";
import About from "./About";
import ScrollToTopOnRedirect from "./ScrollToTopOnRedirect";
import { useEffect, useState } from "react";
import {
  getStorage,
  ref as ref_storage,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import firebaseApp from "./firebase";
import Products from "./Products";
import Contact from "./Contact";
import Inventory from "./Inventory";
import Batch from "./Batch";
import { getDatabase, ref as ref_database, onValue } from "firebase/database";
import Login from "./Login";
import Error404 from "./Error404";
import Unfinished from "./Unfinished";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import History from "./History";

function App() {
  const [historystatus, setHistoryStatus] = useState(false);
  const [loginstatus, setLoginStatus] = useState(false);
  const auth = getAuth();
  const [userDat, setUserDat] = useState(null);

  const checkAuthState = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserDat(user);
        console.log("User is signed in:");
      } else {
        console.log("User is signed out");
        setUserDat(null);
      }
    });
  };
  useEffect(() => {
    checkAuthState();
  });
  /*-------------- 
  Storage-------
--------------*/
  const [aboutImg, setAboutImg] = useState([]);
  const [svgImg, setSvgImg] = useState([]);
  const [bannerImg, setBannerImg] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const storage = getStorage(firebaseApp);
        const aboutimgFolderRef = ref_storage(storage, "img/about");
        const svgimgFolderRef = ref_storage(storage, "img/svg_icon");
        const bannerimgFolderRef = ref_storage(storage, "img/banner");
        const galleryFolderRef = ref_storage(storage, "img/gallery");
        const logoFolderRef = ref_storage(storage, "img/logo");

        const aboutimgitems = await listAll(aboutimgFolderRef);
        const svgimgitems = await listAll(svgimgFolderRef);
        const bannerimgitems = await listAll(bannerimgFolderRef);
        const galleryitems = await listAll(galleryFolderRef);
        const logoitems = await listAll(logoFolderRef);

        const aboutimgurl = await Promise.all(
          aboutimgitems.items.map(async (item) => {
            return getDownloadURL(item);
          })
        );
        const svgimgurl = await Promise.all(
          svgimgitems.items.map(async (item) => {
            return getDownloadURL(item);
          })
        );
        const bannerimgurl = await Promise.all(
          bannerimgitems.items.map(async (item) => {
            return getDownloadURL(item);
          })
        );
        const galleryurl = await Promise.all(
          galleryitems.items.map(async (item) => {
            return getDownloadURL(item);
          })
        );
        const logourl = await Promise.all(
          logoitems.items.map(async (item) => {
            return getDownloadURL(item);
          })
        );

        setAboutImg(aboutimgurl);
        setSvgImg(svgimgurl);
        setBannerImg(bannerimgurl);
        setGallery(galleryurl);
        setLogo(logourl);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);
  const aboutimages = [bannerImg[4]];
  const commonimages = [aboutImg[0], aboutImg[4], bannerImg[3]];
  const commonsvgs = [svgImg[17], svgImg[12], svgImg[8]];
  const homesvgs = [svgImg[15], svgImg[13], svgImg[14]];
  const homeimages = [aboutImg[3], aboutImg[1], aboutImg[2]];
  const carouselimages = [bannerImg[5], bannerImg[6], bannerImg[7]];
  const checkmark = svgImg[5];
  const productbanner = bannerImg[0];
  const contactbanner = bannerImg[1];
  const inventorybanner = bannerImg[8];
  const loginbanner = bannerImg[9];
  const errorimage = bannerImg[10];

  /*----------------
  Database -------  
  ----------------*/

  const [homeData, setHomeData] = useState([]);
  const [carouselData, setCarouselData] = useState([]);
  const [commonCarouselData, setCommonCarouselData] = useState([]);
  const [serviceWrap, setServiceWrap] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [items, setItems] = useState([]);
  const [batchItems, setBatchItems] = useState([]);
  const [batches, setBatches] = useState([]);
  const [unfinished, setUnfinished] = useState([]);
  const database2 = getDatabase(
    firebaseApp,
    "https://pm-internship-review-default-rtdb.firebaseio.com"
  );

  useEffect(() => {
    const fetchData = async () => {
      const dbRef1 = ref_database(database2, "bcarousel");
      const dbRef2 = ref_database(database2, "carousel");
      const dbRef3 = ref_database(database2, "home");
      const dbRef4 = ref_database(database2, "products");
      const dbRef5 = ref_database(database2, "servicewrap");

      onValue(
        dbRef1,
        (snapshot) => {
          const bcarousel = snapshot.val();

          if (bcarousel) {
            const bcarouselarray = Object.values(bcarousel).map((inv) => ({
              id: inv.id,
              title: inv.title,
              body: inv.body,
            }));
            setCommonCarouselData(bcarouselarray);
          }
        },
        (error) => {
          console.error("Error fetching inventory data:", error);
        }
      );
      onValue(
        dbRef2,
        (snapshot) => {
          const carousel = snapshot.val();

          if (carousel) {
            const carouselarray = Object.values(carousel).map((inv) => ({
              id: inv.id,
              title: inv.title,
              body: inv.body,
            }));
            setCarouselData(carouselarray);
          }
        },
        (error) => {
          console.error("Error fetching inventory data:", error);
        }
      );
      onValue(
        dbRef3,
        (snapshot) => {
          const home = snapshot.val();

          if (home) {
            const homearray = Object.values(home).map((inv) => ({
              id: inv.id,
              title: inv.title,
              body: inv.body,
            }));
            setHomeData(homearray);
          }
        },
        (error) => {
          console.error("Error fetching inventory data:", error);
        }
      );
      onValue(
        dbRef4,
        (snapshot) => {
          const products = snapshot.val();

          if (products) {
            const productsarray = Object.values(products).map((inv) => ({
              id: inv.id,
              title: inv.title,
              body: inv.body,
              xl: inv.xl,
              cat: inv.cat,
            }));
            setProductsData(productsarray);
          }
        },
        (error) => {
          console.error("Error fetching inventory data:", error);
        }
      );
      onValue(
        dbRef5,
        (snapshot) => {
          const service = snapshot.val();

          if (service) {
            const servicearray = Object.values(service).map((inv) => ({
              id: inv.id,
              title: inv.title,
              body: inv.body,
            }));
            setServiceWrap(servicearray);
          }
        },
        (error) => {
          console.error("Error fetching inventory data:", error);
        }
      );
    };
    fetchData();

    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef1 = ref_database(getDatabase(), "inventory");
      const dbRef3 = ref_database(getDatabase(), "batches");
      const dbRef4 = ref_database(getDatabase(), "unfinished");

      onValue(
        dbRef1,
        (snapshot) => {
          const inventory = snapshot.val();

          if (inventory) {
            const inventoryarray = Object.values(inventory).map((inv) => ({
              id: inv.id,
              name: inv.name,
              quantity: inv.quantity,
              price: inv.price,
            }));
            const batchinventoryarray = Object.values(inventory).map((inv) => ({
              id: inv.id,
              name: inv.name,
              quantity: inv.quantity,
            }));
            setBatchItems(batchinventoryarray);
            setItems(inventoryarray);
          }
        },
        (error) => {
          console.error("Error fetching inventory data:", error);
        }
      );
      onValue(
        dbRef3,
        (snapshot) => {
          const batches = snapshot.val();

          if (batches) {
            const batchesarray = Object.values(batches).map((usr) => ({
              id: usr.id,
              name: usr.name,
              company: usr.company,
              person: usr.person,
              items: usr.items,
              date: usr.date,
              unfinished: usr.unfinished,
            }));
            setBatches(batchesarray);
          }
        },
        (error) => {
          console.error("Error fetching batches data:", error);
        }
      );
      onValue(
        dbRef4,
        (snapshot) => {
          const unfinished = snapshot.val();

          if (unfinished) {
            const unfinishedarray = Object.values(unfinished).map((unf) => ({
              id: unf.id,
              name: unf.name,
              quantity: unf.quantity,
              company: unf.company,
            }));
            setUnfinished(unfinishedarray);
          }
        },
        (error) => {
          console.error("Error fetching unfinished data:", error);
        }
      );
    };

    fetchData();

    return () => {};
  }, []);

  const [msg, setMsg] = useState("");
  const [uid, setUid] = useState();

  const handleSignIn = async (values) => {
    try {
      await setPersistence(auth, browserSessionPersistence);

      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.username + "@gmail.com",
        values.password
      );

      const user = userCredential.user;
      setUid(user.uid);

      sessionStorage.setItem("user", values.username);
      setMsg(null);
      setLoginStatus(true);
    } catch (error) {
      setMsg("The Email or Password is Invalid");
      console.error("Authentication error:", error);
    }
  };
  useEffect(() => {
    setHistoryStatus(sessionStorage.getItem("historystatus"));
  }, [historystatus]);

  return (
    <Router>
      <ScrollToTopOnRedirect />

      <div className="app">
        {!historystatus && <Navbar logo={logo} userDat={userDat} />}
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home
                commonimages={commonimages}
                commonsvgs={commonsvgs}
                commonCarouselData={commonCarouselData}
                serviceWrap={serviceWrap}
                homesvgs={homesvgs}
                homedata={homeData}
                homeimages={homeimages}
                carouselData={carouselData}
                carouselimages={carouselimages}
                checkmark={checkmark}
              />
            </Route>
            <Route exact path="/about">
              <About
                commonimages={commonimages}
                commonsvgs={commonsvgs}
                commonCarouselData={commonCarouselData}
                serviceWrap={serviceWrap}
                aboutimages={aboutimages}
                checkmark={checkmark}
              />
            </Route>
            <Route exact path="/products">
              <Products
                productsData={productsData}
                gallery={gallery}
                productbanner={productbanner}
              />
            </Route>
            <Route exact path="/contact">
              <Contact contactbanner={contactbanner} />
            </Route>
            <Route exact path="/login">
              <Login
                loginbanner={loginbanner}
                handleSignIn={handleSignIn}
                msg={msg}
                loginstatus={loginstatus}
              />
            </Route>

            <Route exact path="/inv">
              {userDat !== null && (
                <Inventory invbanner={inventorybanner} items={items} />
              )}
              : {userDat === null && <Error404 errorimage={errorimage} />}
            </Route>

            <Route exact path="/inv/batch">
              {userDat !== null && (
                <Batch
                  invbanner={inventorybanner}
                  items={batchItems}
                  batches={batches}
                  unfinishedgoods={unfinished}
                />
              )}
              :{userDat === null && <Error404 errorimage={errorimage} />}
            </Route>
            <Route exact path="/history">
              <History batches={batches} />
            </Route>
            <Route exact path="/unfinished-goods">
              {userDat !== null && (
                <Unfinished invbanner={inventorybanner} items={unfinished} />
              )}
              : {userDat === null && <Error404 errorimage={errorimage} />}
            </Route>
            <Route path="*">
              <Error404 errorimage={errorimage} />
            </Route>
          </Switch>
        </div>
        {!historystatus && <Footer logo={logo} />}
      </div>
    </Router>
  );
}

export default App;
