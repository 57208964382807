import CountUp from "react-countup";

const Counter = () => {
  return (
    <div className="counter_area">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-md-4">
            <div className="single_counter text-center">
              <h3>
                {" "}
                <span className="counter1">
                  <CountUp start={0} end={2000} duration={3}></CountUp>
                </span>{" "}
                <span>+</span>{" "}
              </h3>
              <span>Happy Customers</span>
            </div>
          </div>
          <div className="col-xl-4 col-md-4">
            <div className="single_counter text-center">
              <h3>
                {" "}
                <span className="counter2">
                  <CountUp start={0} end={100} duration={5.5}></CountUp>
                </span>{" "}
                <span>+</span>{" "}
              </h3>
              <span>Coating Options</span>
            </div>
          </div>
          <div className="col-xl-4 col-md-4">
            <div className="single_counter text-center">
              <h3>
                {" "}
                <span className="counter3">
                  <CountUp start={0} end={20} duration={8}></CountUp>
                </span>{" "}
                <span>+</span>{" "}
              </h3>
              <span>Years of Experience</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
