import { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom";
import { signOut, getAuth } from "firebase/auth";

const Navbar = ({ logo, userDat }) => {
  const auth = getAuth();
  const history = useHistory();
  const handleClick = (event) => {
    event.preventDefault();
    history.push("/contact");

    window.scrollBy({
      top: 1100,
      behavior: "smooth",
    });
  };
  const handleBeforeUnload = () => {
    signOut(auth);
    history.push("/");
  };

  const [isSticky, setSticky] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky("sticky");
      } else {
        setSticky("");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <div className="header-area ">
        <div className="header-top_area d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 ">
                <div className="social_media_links">
                  <a href="https://www.facebook.com">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="https://www.linkedin.com">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-md-6">
                <div className="short_contact_list">
                  <ul>
                    <li>
                      <a
                        href="mailto:pmscoaters@gmail.com"
                        rel="noreferrer"
                        target="_blank"
                      >
                        {" "}
                        <i className="fa fa-envelope"></i> pmscoaters@gmail.com
                      </a>
                    </li>
                    <li>
                      <a
                        href="tel:+919422701244"
                        rel="noreferrer"
                        target="_blank"
                      >
                        {" "}
                        <i className="fa fa-phone"></i>+91 9422701244
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="sticky-header" className={`main-header-area ${isSticky}`}>
          <div className="container">
            <div className="header_bottom_border">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-2">
                  <div className="logo">
                    <NavLink to="/">
                      <img src={logo[3]} alt="" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7">
                  <div className="main-menu  d-none d-lg-block">
                    <nav>
                      <ul id="navigation">
                        <li>
                          <NavLink exact activeClassName="active" to="/">
                            Home
                          </NavLink>
                        </li>
                        <li>
                          <NavLink activeClassName="active" to="/about">
                            About Us
                          </NavLink>
                        </li>
                        <li>
                          <NavLink activeClassName="active" to="/products">
                            Products
                          </NavLink>
                        </li>
                        <li>
                          <NavLink activeClassName="active" to="/contact">
                            Contact Us
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 d-none d-lg-block">
                  <div className="Appointment" id="sticky-indicator">
                    <div className="book_btn d-none d-lg-block">
                      <a href="/contact" onClick={handleClick}>
                        Request Quote
                      </a>
                      {userDat !== null && (
                        <button
                          className="book_btn d-none d-lg-block new_btn"
                          onClick={handleBeforeUnload}
                        >
                          Logout
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mobile_menu d-block d-lg-none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
