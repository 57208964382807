import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({ carouselData, carouselimages }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  console.log(carouselData);

  return (
    <Slider {...settings}>
      {carouselData.map((carousel, index) => (
        <div class="slider_area">
          <div class="slider_active">
            <div
              className="single_slider d-flex align-items-center overlay2"
              style={{ backgroundImage: `url(${carouselimages[index]})` }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="slider_text">
                      <h3>{carousel.title}</h3>
                      <p>{carousel.body}</p>
                      <div className="video_service_btn">
                        <Link to="/products" className="boxed-btn3">
                          Products
                        </Link>
                        <Link to="/about" className="boxed-btn3-white">
                          {" "}
                          <i className="fa fa-play"></i>
                          About Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
