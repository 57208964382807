import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import "./css/Inventory.css";
import { getDatabase, ref, update, set, remove } from "firebase/database";
import { app } from "./firebase";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState } from "react";

const Unfinished = ({ invbanner, items }) => {
  const AddItemSchema = Yup.object().shape({
    name: Yup.string()
      .required("This is a required Field")
      .min(2, "The name Should be at least 2 characters long"),
    quantity: Yup.number()
      .required("This is a Required Field")
      .moreThan(0, "The quantity should be more than 1"),
    company: Yup.string()
      .required("This is a required Field")
      .min(2, "The Company Should be at least 2 characters long"),
  });
  const db = getDatabase(app);
  const [incrementValue, setIncrementValue] = useState(0);
  const [decrementValue, setDecrementValue] = useState(0);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const userDataString = sessionStorage.getItem(
      "firebase:authUser:AIzaSyARtZtGcTVyARm-iZrX1YNvhiBSMuGCib4:[DEFAULT]"
    );
    if (userDataString) {
      const userDataJSON = JSON.parse(userDataString);
      setUserData(userDataJSON.email);
    }
  }, []);

  const date = new Date();
  const keyID = date.getTime() + Math.floor(Math.random() * 1500);

  const handleIncreaseQuantity = (itemId, amount) => {
    const selectedItem = items.filter((ite) => ite.id === itemId);
    const incitem = parseInt(selectedItem[0].quantity) + parseInt(amount);
    update(ref(db, `unfinished/${itemId}`), {
      quantity: incitem,
    });
    setIncrementValue(0);
  };
  const handleDecreaseQuantity = (itemId, amount) => {
    const selectedItem = items.filter((ite) => ite.id === itemId);
    const incitem = parseInt(selectedItem[0].quantity) - parseInt(amount);
    update(ref(db, `unfinished/${itemId}`), {
      quantity: incitem,
    });
    setDecrementValue(0);
  };

  const handleAddItem = (values, { setSubmitting, resetForm }) => {
    set(ref(db, `unfinished/${keyID}`), {
      id: keyID,
      name: values.name.toString(),
      quantity: values.quantity,
      user: sessionStorage.getItem("user"),
      company: values.company.toString(),
    });
    setSubmitting(false);
    resetForm();
  };

  const data = items.map((item) => ({
    name: item.name,
    quantity: item.quantity,
  }));
  useEffect(() => {
    document.title = "Unfinished | PM Speciality Coatings";
    return () => {
      document.title = "PM Speciality Coatings";
    };
  }, []);

  const handleDelete = (id) => {
    remove(ref(db, `inventory/${id}`));
  };

  return (
    <section className="inventory">
      <div
        className="bradcam_area"
        style={{ backgroundImage: `url(${invbanner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bradcam_text text-center">
                <h3>Unfinished Goods</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-container1">
        <h2 className="section-title1">Chart</h2>
        <div className="button-container">
          <Link to="/inv/batch" className="link-button">
            Batches
          </Link>
          <Link to="/inv" className="link-button">
            Inventory
          </Link>
        </div>
        <BarChart width={900} height={350} data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Bar dataKey="quantity" fill="#8884d8" />
        </BarChart>
      </div>

      <div className="table-container1">
        <h2 className="section-title1">Inventory Table</h2>
        <table className="inventory-table1">
          <thead>
            <tr>
              <th>Name</th>
              <th>Quantity</th>
              <th>Company</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items
              ? items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.company}</td>
                    <td style={{ width: "500px" }}>
                      <Popup
                        trigger={
                          <button style={{ marginLeft: "20px" }}>
                            Increase
                          </button>
                        }
                        position="right center"
                      >
                        <div>
                          <input
                            type="number"
                            value={incrementValue}
                            onChange={(e) => setIncrementValue(e.target.value)}
                          />
                          <button
                            onClick={() =>
                              handleIncreaseQuantity(item.id, incrementValue)
                            }
                          >
                            Increment
                          </button>
                        </div>
                      </Popup>
                      <Popup
                        trigger={
                          <button style={{ marginLeft: "20px" }}>
                            Decrease
                          </button>
                        }
                        position="right center"
                      >
                        <div>
                          <input
                            type="number"
                            value={decrementValue}
                            onChange={(e) => setDecrementValue(e.target.value)}
                          />
                          <button
                            onClick={() =>
                              handleDecreaseQuantity(item.id, decrementValue)
                            }
                          >
                            Decrement
                          </button>
                        </div>
                      </Popup>
                      {userData === "admin@gmail.com" && (
                        <button
                          style={{ marginLeft: "20px" }}
                          onClick={() => handleDelete(item.id)}
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              : "Load"}
          </tbody>
        </table>
      </div>

      <div className="add-item-container1">
        <h2 className="section-title1 contact-form">Add New Goods</h2>

        <Formik
          initialValues={{
            name: "",
            quantity: "",
            company: "",
          }}
          validationSchema={AddItemSchema}
          onSubmit={handleAddItem}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              {errors.name && touched.name ? (
                <sub style={{ color: "red" }}>{errors.name}</sub>
              ) : null}
              <Field
                type="text"
                placeholder="Name"
                name="name"
                autoComplete="off"
              />
              {errors.quantity && touched.quantity ? (
                <sub style={{ color: "red" }}>{errors.quantity}</sub>
              ) : null}
              <Field
                type="number"
                min="1"
                placeholder="Quantity"
                name="quantity"
                autoComplete="off"
              />
              {errors.company && touched.company ? (
                <sub style={{ color: "red" }}>{errors.company}</sub>
              ) : null}
              <Field
                type="text"
                placeholder="Company"
                name="company"
                autoComplete="off"
              />
              <button
                type="submit"
                className="boxed-btn contact-form-btn"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default Unfinished;
