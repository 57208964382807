import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ScrollToTopOnRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollBy({
        top: 0,
        behavior: "smooth",
      });
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default ScrollToTopOnRedirect;
