/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom/cjs/react-router-dom";
const Footer = ({ logo }) => {
  return (
    <section className="footer">
      <div class="footer_top">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-lg-4">
              <div class="footer_widget">
                <div class="footer_logo">
                  <Link to="/">
                    <img src={logo[3]} alt="" />
                  </Link>
                </div>
                <p>
                  Coating Solutions tailored to the specific needs of each
                  customer.
                </p>
                <div class="socail_links">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com">
                        <i class="ti-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com">
                        <i class="ti-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-2 offset-xl-1 col-md-6 col-lg-3">
              <div class="footer_widget">
                <h3 class="footer_title"></h3>
                <ul>
                  <li>
                    <Link to="#"></Link>
                  </li>
                  <li>
                    <Link to="#"></Link>
                  </li>
                  <li>
                    <Link to="#"></Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-2 col-md-6 col-lg-2">
              <div class="footer_widget">
                <h3 class="footer_title">Pages</h3>
                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-3">
              <div class="footer_widget">
                <h3 class="footer_title">Address</h3>
                <ul>
                  <li>
                    <a href="https://maps.app.goo.gl/Uea6KpHAYo2hR96v8">
                      Plot No. L-22, <br /> MIDC Ambad, Nashik.422010
                      Maharashtra INDIA
                    </a>
                  </li>
                  <li>
                    <a
                      href="tel:+919422701244"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91 9422701244
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:pmscoaters@gmail.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      pmscoaters@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-right_text">
        <div class="container">
          <div class="footer_border"></div>
          <div class="row">
            <div class="col-xl-12">
              <p class="copy_right text-center">
                © Copyright 2023, PM Speciality Coatings
                <br />
                All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
