import React from "react";
import ReactDOM from "react-dom/client";
import "./css/animate.css";
import "./css/bootstrap.min.css";
import "./css/flaticon.css";
import "./css/font-awesome.min.css";
import "./css/gijgo.css";
import "./css/magnific-popup.css";
import "./css/nice-select.css";
import "./css/slick.css";
import "./css/slicknav.css";
import "./css/style.css";
import "./css/style.css.map";
import "./css/style.min.css";
import "./css/style.min.css.map";
import "./css/theme-default.css";
import "./css/themify-icons.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
