import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ContactShort from "./ContactShort";
import Counter from "./Counter";
import { useEffect } from "react";

const Common = ({
  commonimages,
  commonsvgs,
  commonCarouselData,
  serviceWrap,
  checkmark,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--background-image",
      `url(${checkmark})`
    );
  }, [checkmark]);

  return (
    <section className="about">
      <div className="about_area ">
        <div className="container-fluid p-0">
          <div className="row no-gutters align-items-center about_content">
            <div className="col-xl-6 col-lg-6">
              <div className="about_image">
                <img src={commonimages[0]} alt="drum" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about_info">
                <h3>
                  More Than a Company,
                  <br />
                  PM Speciality Is a Concept
                </h3>
                <p>
                  An ISO 9001:2015 Company which started its activity more than
                  20 years ago,
                  <br />
                  with the clear conviction of satisfying the needs of its
                  customers.
                </p>
                <ul>
                  <li>
                    We approach opportunities with imagination and flexibility.
                  </li>
                  <li>
                    We perform excellent services tailored to our Customers.
                  </li>
                  <li>
                    We promptly and explicitly assume our responsibilities.
                  </li>
                </ul>

                <div className="about_btn">
                  <a
                    className="boxed-btn3"
                    href="/about"
                    style={{ textDecoration: "none" }}
                  >
                    About Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Counter />
      <div
        className="about_wrap_area"
        style={{ backgroundImage: `url(${commonimages[2]})` }}
      >
        <div className="container">
          <div className="row">
            {serviceWrap.map((serv, index) => (
              <div className="col-xl-4 col-md-6 col-lg-4">
                <div className="single_service_wrap text-center">
                  <img src={commonsvgs[index]} alt="target" />
                  <h3>{serv.title}</h3>
                  <p>{serv.body}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="financial_solution_area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="financial_active owl-carousel">
                <Slider {...settings}>
                  {commonCarouselData.map((car) => (
                    <div className="single_finalcial_wrap">
                      <h3>{car.title}</h3>
                      <p>{car.body}</p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="finance_thumb">
                <img src={commonimages[1]} alt="O-rings" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactShort />
    </section>
  );
};

export default Common;
