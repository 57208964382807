import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useEffect } from "react";

const Contact = ({ contactbanner }) => {
  const emailRegex =
    '(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';
  const SignupSchema = Yup.object().shape({
    message: Yup.string()
      .required("This is a required Field")
      .min(2, "The Message Should be at least 2 characters long"),
    name: Yup.string()
      .min(2, "Name Should be at least 2 characters")
      .max(50, "Name is too long")
      .required("This is a Required Field"),
    email: Yup.string()
      .matches(emailRegex, "Email is not valid")
      .required("This is a Required Field"),
    subject: Yup.string()
      .required("This is a required Field")
      .min(2, "The Subject Should be at least 2 characters long"),
  });
  useEffect(() => {
    document.title = "Contact | PM Speciality Coatings";
    return () => {
      document.title = "PM Speciality Coatings";
    };
  }, []);

  const sendEmail = async (values, { setSubmitting, resetForm }) => {
    alert("There is an Error with the API currently", values);
    setSubmitting(false);
    resetForm();
  };

  return (
    <section className="contact">
      <div
        className="bradcam_area"
        style={{ backgroundImage: `url(${contactbanner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bradcam_text text-center">
                <h3>Contact Us</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-section">
        <div className="container">
          <div className="d-none d-sm-block mb-5 pb-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234.4000063280564!2d73.73714459491201!3d19.949669247948787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdd936353a56807%3A0x5d30d3f606a20fd4!2sPM%20SPECIALITY%20COATINGS!5e0!3m2!1sen!2sin!4v1707201505766!5m2!1sen!2sin"
              width="100%"
              title="map"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>

            <div className="row mt-5">
              <div className="col-12">
                <h2 className="contact-title">Contact Us</h2>
              </div>
              <div className="col-lg-8 contact-form">
                <Formik
                  initialValues={{
                    message: "",
                    name: "",
                    email: "",
                    subject: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={sendEmail}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form>
                      {errors.message && touched.message ? (
                        <sub style={{ color: "red" }}>{errors.message}</sub>
                      ) : null}
                      <Field
                        as="textarea"
                        id="message"
                        placeholder="Message"
                        name="message"
                        rows="4"
                      />
                      {errors.name && touched.name ? (
                        <sub style={{ color: "red" }}>{errors.name}</sub>
                      ) : null}
                      <Field
                        type="text"
                        placeholder="Name"
                        name="name"
                        autoComplete="off"
                      />
                      {errors.email && touched.email ? (
                        <sub style={{ color: "red" }}>{errors.email}</sub>
                      ) : null}
                      <Field
                        type="text"
                        placeholder="Email Id"
                        name="email"
                        autoComplete="off"
                      />
                      {errors.subject && touched.subject ? (
                        <sub style={{ color: "red" }}>{errors.subject}</sub>
                      ) : null}
                      <Field
                        type="text"
                        placeholder="Subject"
                        name="subject"
                        autoComplete="off"
                      />
                      <button
                        type="submit"
                        className="boxed-btn contact-form-btn"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="col-lg-3 offset-lg-1">
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <i className="ti-home"></i>
                  </span>
                  <div className="media-body">
                    <a
                      href="https://maps.app.goo.gl/HLRh2jseveAX665U6"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h3>PM Speciality Coatings</h3>
                    </a>
                    <p>
                      Plot No. L-22, <br /> MIDC Ambad, Nashik. 422010
                      Maharashtra INDIA
                    </p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <i className="ti-tablet"></i>
                  </span>
                  <div className="media-body">
                    <a
                      href="tel:+919422701244"
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <h3>+91 9422701244</h3>
                    </a>
                    <p>
                      Sunday To Friday (9am to 5pm)
                      <br />
                      Saturday Closed
                    </p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <i className="ti-email"></i>
                  </span>
                  <div className="media-body">
                    <a
                      href="mailto:pmscoaters@gmail.com"
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <h3>pmscoaters@gmail.com</h3>
                    </a>
                    <p>For Inquiries or Assistance, Please Contact Us.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Contact;
