import { initializeApp } from "firebase/app";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyARtZtGcTVyARm-iZrX1YNvhiBSMuGCib4",
  authDomain: "pm-speciality-coatings.firebaseapp.com",
  databaseURL: "https://pm-speciality-coatings-default-rtdb.firebaseio.com",
  projectId: "pm-speciality-coatings",
  storageBucket: "pm-speciality-coatings.appspot.com",
  messagingSenderId: "477975371068",
  appId: "1:477975371068:web:0b745121ade3614b6e5239",
  measurementId: "G-HMS8XTXZSC",
};

const firebaseApp = initializeApp(firebaseConfig);

export const app = initializeApp(firebaseConfig);
export default firebaseApp;
