import { useEffect } from "react";
import Common from "./Common";

const About = ({
  commonimages,
  commonsvgs,
  aboutimages,
  commonCarouselData,
  serviceWrap,
  checkmark,
}) => {
  useEffect(() => {
    document.title = "About | PM Speciality Coatings";
    return () => {
      document.title = "PM Speciality Coatings";
    };
  }, []);
  return (
    <section className="about">
      <div
        className="bradcam_area"
        style={{ backgroundImage: `url(${aboutimages[0]})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bradcam_text text-center">
                <h3>About Us</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Common
        commonimages={commonimages}
        commonsvgs={commonsvgs}
        commonCarouselData={commonCarouselData}
        serviceWrap={serviceWrap}
        checkmark={checkmark}
      />
    </section>
  );
};

export default About;
