import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./css/history.css";
import CalendarSelector from "./CalendarSelector";
import { useState } from "react";
import { getDatabase, ref as ref_database, onValue } from "firebase/database";

const History = ({ batches }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [filteredState, setFilteredState] = useState(false);

  const date = new Date(startDate);
  var year = date.getFullYear();
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  var formattedDate = `${year}-${month}-${day}`;

  const date1 = new Date(endDate);
  var year1 = date1.getFullYear();
  var month1 = ("0" + (date1.getMonth() + 1)).slice(-2);
  var day1 = ("0" + date1.getDate()).slice(-2);
  var formattedDate1 = `${year1}-${month1}-${day1}`;
  console.log(formattedDate, formattedDate1);
  console.log(batches);

  const handleDatesChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const history = useHistory();
  const handleBack = () => {
    sessionStorage.removeItem("historystatus");
    history.push("/inv/batch");
  };

  const filteredItems = batches.map((bat) => bat.items);
  const filteredItems1 = batches.map((bat) => bat.unfinished);
  const filteredDataItems = filteredData.map((bat) => bat.items);
  const filteredDataItems1 = filteredData.map((bat) => bat.unfinished);
  const fetchData = async () => {
    const dbRef = ref_database(getDatabase(), "batches");

    onValue(
      dbRef,
      (snapshot) => {
        const batches = snapshot.val();

        if (batches) {
          const batchesarray = Object.values(batches)
            .filter(
              (batch) =>
                batch.date >= formattedDate && batch.date <= formattedDate1
            )
            .map((usr) => ({
              id: usr.id,
              name: usr.name,
              company: usr.company,
              person: usr.person,
              items: usr.items,
              date: usr.date,
              unfinished: usr.unfinished,
            }));
          setFilteredData(batchesarray);
        }
      },
      (error) => {
        console.error("Error fetching batches data:", error);
      }
    );
  };

  const handleFilter = () => {
    setFilteredState(true);
    fetchData();
  };

  const handleClear = () => {
    setFilteredState(false);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <section className="history">
      {!filteredState && (
        <div className="non-filtered">
          <div className="body">
            <div className="container1">
              <div className="upper-bar">
                <h2 className="section-title1">Batch</h2>
                <button onClick={handleBack}>Go Back</button>
                <button className="print-btn" onClick={handlePrint}>
                  Print
                </button>
                <div className="filter">
                  <CalendarSelector
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={handleDatesChange}
                    handleFilter={handleFilter}
                    handleClear={handleClear}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container1">
            <table className="inventory-table1">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>Person</th>
                  <th>Date</th>
                  <th>Unfinished Goods</th>
                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                {batches.map((bat, index) => (
                  <tr key={index}>
                    <td>{bat.name}</td>
                    <td>{bat.company}</td>
                    <td>{bat.person}</td>
                    <td>{bat.date}</td>
                    <td>
                      {filteredItems1[index].map((fil) => (
                        <div key={fil.name}>
                          <strong>{fil.name}: </strong> {fil.quantity}
                        </div>
                      ))}
                    </td>
                    <td>
                      {filteredItems[index].map((fil) => (
                        <div key={fil.name}>
                          <strong>{fil.name}: </strong> {fil.quantity}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {filteredState && (
        <div className="filtered">
          <div className="body">
            <div className="container1">
              <div className="upper-bar">
                <h2 className="section-title1">Batch</h2>
                <button onClick={handleBack}>Go Back</button>
                <button className="print-btn" onClick={() => window.print()}>
                  Print
                </button>
                <div className="filter">
                  <CalendarSelector
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={handleDatesChange}
                    handleFilter={handleFilter}
                    handleClear={handleClear}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container1">
            <table className="inventory-table1">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>Person</th>
                  <th>Date</th>
                  <th>Unfinished Goods</th>
                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((bat, index) => (
                  <tr key={index}>
                    <td>{bat.name}</td>
                    <td>{bat.company}</td>
                    <td>{bat.person}</td>
                    <td>{bat.date}</td>
                    <td>
                      {filteredDataItems[index].map((fil) => (
                        <div key={fil.name}>
                          <strong>{fil.name}: </strong> {fil.quantity}
                        </div>
                      ))}
                    </td>
                    <td>
                      {filteredDataItems1[index].map((fil) => (
                        <div key={fil.name}>
                          <strong>{fil.name}: </strong> {fil.quantity}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </section>
  );
};

export default History;
