import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CalendarSelector = ({
  startDate,
  endDate,
  onDatesChange,
  handleFilter,
  handleClear,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    onDatesChange(date, selectedEndDate);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    onDatesChange(selectedStartDate, date);
  };

  return (
    <div>
      <h3>Start Date:</h3>
      <DatePicker
        selected={selectedStartDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={selectedStartDate}
        endDate={selectedEndDate}
      />
      <h3>End Date:</h3>
      <DatePicker
        selected={selectedEndDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        minDate={selectedStartDate}
      />
      <div>
        <button style={{ marginLeft: "20px" }} onClick={handleFilter}>
          Filter
        </button>
        <button style={{ marginLeft: "20px" }} onClick={handleClear}>
          Clear
        </button>
      </div>
    </div>
  );
};

export default CalendarSelector;
