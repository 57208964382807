import Common from "./Common";
import Carousel from "./Carousel";
import { useEffect } from "react";

const Home = ({
  commonimages,
  commonsvgs,
  homesvgs,
  homedata,
  homeimages,
  carouselData,
  carouselimages,
  commonCarouselData,
  serviceWrap,
  checkmark,
}) => {
  useEffect(() => {
    document.title = "Home | PM Speciality Coatings";
    return () => {
      document.title = "PM Speciality Coatings";
    };
  }, []);
  return (
    <section className="home">
      <div className="slider_area">
        <div className="slider_active owl-carousel">
          <Carousel
            carouselData={carouselData}
            carouselimages={carouselimages}
          />
        </div>
      </div>
      <div className="service_area">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section_title text-center mb-50">
                <h3>Explore Our Solutions</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {homedata.map((home, index) => (
              <div className="col-xl-4 col-md-4">
                <div
                  className="single_service"
                  style={{ backgroundImage: `url(${homeimages[index]})` }}
                >
                  <div className="service_hover">
                    <img src={homesvgs[index]} alt="home" />
                    <h3>{home.title}</h3>
                    <div className="hover_content">
                      <div className="hover_content_inner">
                        <h4>
                          <u>{home.title}</u>
                        </h4>
                        <p>{home.body}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Common
        commonimages={commonimages}
        commonsvgs={commonsvgs}
        commonCarouselData={commonCarouselData}
        serviceWrap={serviceWrap}
        checkmark={checkmark}
      />
    </section>
  );
};

export default Home;
